import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from 'glamor';

const patreon_card = css({
    color: 'var(--light)',
    backgroundColor: 'var(--dark)',
    display: 'inline-block',
    padding: '10px',
    borderRadius: '15px',
    border: '2px solid var(--primary-color)'
});

const PatreonCard = (props) => {
    const { t } = useTranslation();
    return (
        <div className={`${patreon_card} ${props.className}`} dangerouslySetInnerHTML={{ __html:t('patreon', {returnObjects: true}).card}}/>
    )
}
export default PatreonCard;