import React from 'react'
import Layout from '../components/layout';
import SEO from '../components/seo';
import { Header } from '../components/Header/Header';
import ToolList from '../components/ToolsList/ToolList';

export const ToolsPage = ({location, siteTitle}) => {
    return (
        <Layout location={location} title={siteTitle}>
            <SEO title="Tools" />
            <Header />
            <ToolList />
        </Layout>
    )
}

export default ToolsPage;