import React, { useEffect } from 'react';
import 'imagehover.css/css/imagehover.min.css';
import Cards from '../Card/Card';
import EasyResizer from '../../../content/assets/tools/easy_resizer.png';
import AtlasTool from '../../../content/assets/tools/atlas_packer.png';
import AnimatorTool from '../../../content/assets/tools/animator_tool.png';
import AudioSprite from '../../../content/assets/tools/audio_sprite.png';
import Phaser3Cli from '../../../content/assets/tools/phaser3_cli.png';
import ttf2png from '../../../content/assets/tools/ttf2png.png';
import { Observer, GLOBAL_EVENT } from '../../Observer';
import { useTranslation } from 'react-i18next';
import PatreonCard from '../PatreonCard';

const ToolList = () => {
    const { t } = useTranslation();
    useEffect(() => {
        Observer.emit(GLOBAL_EVENT.SET_LANG);
    });
    return (
        <div>
            <div className="text-center mt-3 container">
                <div className="row">
                    <div className="col-12">
                        <PatreonCard />
                        <h5 className="text-white-color mt-3">Tools</h5>
                        <div className="text-center mt-3 blogList container">
                            <Cards className="mx-0 mx-sm-2 my-1 my-sm-3"
                                title={t('tools_page', { returnObjects: true }).easy_resizer.title}
                                excerpt={t('tools_page', { returnObjects: true }).easy_resizer.body}
                                tag='tool'
                                cover={EasyResizer}
                                link="/tool/easy-resizer"
                            />
                            <Cards className="mx-0 mx-sm-2 my-1 my-sm-3"
                                title={t('tools_page', { returnObjects: true }).atlas_packer.title}
                                excerpt={t('tools_page', { returnObjects: true }).atlas_packer.body}
                                tag='tool'
                                cover={AtlasTool}
                                link="/tool/atlas-packer"
                            />
                            <Cards className="mx-0 mx-sm-2 my-1 my-sm-3"
                                link="/tool/animator"
                                title={t('tools_page', { returnObjects: true }).animator_tool.title}
                                excerpt={t('tools_page', { returnObjects: true }).animator_tool.body}
                                tag='tool'
                                cover={AnimatorTool}
                            />
                            <Cards className="mx-0 mx-sm-2 my-1 my-sm-3"
                                link="https://gammafp.github.io/audio-sprite-gamma/"
                                title={t('tools_page', { returnObjects: true }).audio_sprite.title}
                                excerpt={t('tools_page', { returnObjects: true }).audio_sprite.body}
                                tag='tool'
                                cover={AudioSprite}
                            />
                            <Cards className="mx-0 mx-sm-2 my-1 my-sm-3"
                                link="https://www.npmjs.com/package/phaser3-cli"
                                title={t('tools_page', { returnObjects: true }).phaser3_cli.title}
                                excerpt={t('tools_page', { returnObjects: true }).phaser3_cli.body}
                                tag='tool'
                                cover={Phaser3Cli}
                            />
                            <Cards className="mx-0 mx-sm-2 my-1 my-sm-3"
                                link="http://ttf2png.ga"
                                title={t('tools_page', { returnObjects: true }).ttf2png.title}
                                excerpt={t('tools_page', { returnObjects: true }).ttf2png.body}
                                tag='tool'
                                cover={ttf2png}
                            />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default ToolList;